import React from 'react';
import SymbolDetail from './SymbolDetail';

const Index = () => {
    const style = {
        width: '100%',
        height: '385px',
        overflow: 'auto',
        maxHeight: '385px',
    };
    return <SymbolDetail style={style} />;
};

export default Index;
