import React from 'react';
import styled, { css } from 'styled-components';

import { Wrap } from './SectionWrap';
import LandingSectionTitle from './LandingSectionTitle';
import LandingSectionSubTitle from './LandingSectionSubTitle';
import LandingSectionImageWrap from './LandingSectionImageWrap';
import LandingSectionButtonWrap from './LandingSectionButtonWrap';
import LandingSectionContents from './LandingSectionContents';
import OutlinedButton from './OutlinedButton';
import useScreenSize from '../../../hooks/useScreenSize';
import SECTION02_BACKGROUND from '../../../assets/landing/section2_background@2x.png';
import SECTION02_CAPTURE from '../../../assets/landing/section2_pc@2x.png';
import { useHistory } from 'react-router-dom';

const Section02 = () => {
    const history = useHistory();
    const { isMobile } = useScreenSize();
    return (
        <Section mobile={isMobile}>
            <div className="inner">
                {isMobile && (
                    <LandingSectionImageWrap>
                        <div >
                            <img src={SECTION02_CAPTURE} style={{ width: '100%', height: 'auto' }} alt="section2" />
                        </div>
                    </LandingSectionImageWrap>
                )}
                <div>
                    <LandingSectionTitle>
                        {isMobile ? (
                            <p>
                                Transparent chart,
                                <br />
                                Trust with traders
                            </p>
                        ) : (
                            <>Transparent chart, Trust with traders</>
                        )}
                    </LandingSectionTitle>
                    <LandingSectionSubTitle>Twentyfour hour Live</LandingSectionSubTitle>
                    <LandingSectionContents>
                        {isMobile ? (
                            <p>
                                Stable chart reflection with updated mechanism, <br /> Various data being provided.
                            </p>
                        ) : (
                            <>Stable chart reflection with updated mechanism, Various data being provided.</>
                        )}
                    </LandingSectionContents>
                    <LandingSectionButtonWrap>
                        <OutlinedButton onClick={() => history.push('/trade')}>Start Trading</OutlinedButton>
                    </LandingSectionButtonWrap>
                </div>
                {!isMobile && (
                    <LandingSectionImageWrap>
                        <div style={{width: '100% !important', height: 'auto !important', top: '15%', position: 'absolute'}}>
                            <img src={SECTION02_CAPTURE} style={{ width: '575px', height: '379px' }} alt="section2" />
                        </div>
                    </LandingSectionImageWrap>
                )}
            </div>
            <div className="absolute-background">
                <img src={SECTION02_BACKGROUND} alt="section2_background" />
            </div>
        </Section>
    );
};

export default Section02;

const Section = styled(Wrap)`
    background: #f3f6f8;
    & > .absolute-background {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 0;
        height: auto;
        width: 676px;
    }
    ${({ mobile }) =>
        mobile &&
        css`
            & > .absolute-background {
                position: absolute;
                top: 0;
                right: 0;
                z-index: 0;
                height: auto;
                width: 235px;
            }
        `}
`;
