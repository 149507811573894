import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk';
import reducers from './reducers';
import { loggerMiddleware } from './middlewares/logger-middleware';

import { composeWithDevTools } from 'redux-devtools-extension';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ["userReducer"]
}

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = createStore(
    persistedReducer,
    {},
    composeWithDevTools(
        applyMiddleware(thunk, loggerMiddleware),
        // other store enhancers if any
    ),
);

export const persistor = persistStore(store)
