import React, {useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import TabMenu from '../../../components/common/TabMenu';
import OpenOrders from '../../../components/client/UserTabComponents/OpenOrders';
import TradingHistory from '../../../components/client/UserTabComponents/TradingHistory';
import OpenPositions from '../../../components/client/UserTabComponents/OpenPositions';
import PositionDetail from '../../../components/client/UserTabComponents/PositionDetail';
import DebugHelper from '../../../components/common/DebugHelper';
import OrderTabMenu from '../../../components/client/OrderTabComponents/OrderTabs';
import CoinInfo from '../../../components/client/CoinInfo';
import FavoriteList from '../../../components/client/SymbolList/FavoriteList';
import SymbolList from '../../../components/client/SymbolList';
import Chart from '../../../components/client/Chart/Chart';
import OrderConcluded from '../../../components/client/OrderConcluded';
import OrderRegistered from '../../../components/client/OrderRegistered';
import Trades from '../../../components/client/Trades';
import UserMargin from '../../../components/client/UserMargin';
import OrderBook from '../../../components/client/OrderBook';
import SymbolDetail from '../../../components/client/SymbolDetail';
import * as LANGUAGE from '../../../constants/Language';
import NewOrderFormTest from '../../../components/client/OrderTabComponents/NewOrder/NewOrderFormTest';
import MessageBox from '../../../components/client/MessageBox';
import ClientLayout from '../../../components/client/ClientLayout';
import useCurrentLanguage from '../../../hooks/useCurrentLanguage';
import Layout from '../../../components/layout';
import socketService from "../../../states/socketAgent/SocketService";
import useSymbolList from "../../../hooks/useSymbolList";

const TradingPage = () => {
    const { currentSymbol } = useSymbolList();
    const { currentLanguage } = useCurrentLanguage();

  useEffect(() => {
    const info = {
      Header: {
        function: 'D',
        termtype: 'HTS',
        trcode: 't9731',
        trid: '1',
      },
      Input1: {
        szCurNo: currentSymbol,
        cTermDiv: '2',
        szCritDate: '99999999',
        szCritTime: '999999999',
        nMinTerm: '1',
        nReqCnt: '500',
      },
    };

    socketService.sendToAgent(info);
  },[])

    // const isDebugMode: string | null = new URLSearchParams(window.location.search).get('debug');

    return (
        <Layout theme="dark">
            <div style={{ height: 80 }}></div>
            <div
                style={{
                    // maxWidth: 2100,
                    // margin: 'auto',
                    // minWidth: 1445,
                    width: '100vw',
                    overflow: 'auto',
                    fontSize: '12px',
                    // paddingRight: 30,
                }}
            >
                <Grid
                    container
                    xs={12}
                    style={{ padding: '0 15px 0 0px', minWidth: 1500, overflow: 'auto' }}
                    justify="center"
                    alignItems="flex-start"
                >
                    <Grid xs={12}>
                        <CoinInfo />
                    </Grid>
                    <Grid item style={{ flex: '1', marginRight: 5, flexWrap: 'nowrap' }}>
                        <Grid
                            container
                            style={{
                                margin: '5px 5px 5px 0',
                            }}
                        >
                            <Grid style={{ flex: '1', marginRight: 5 }}>
                                <Chart />
                            </Grid>
                            <Grid>
                                <TabMenu
                                    menu={
                                        currentLanguage === LANGUAGE.KOREAN
                                            ? ['호가', '체결']
                                            : ['Order Book', 'Trades']
                                    }
                                    components={[<OrderBook key={0} />, <Trades key={1} />]}
                                    tabWidth={145}
                                />
                            </Grid>
                        </Grid>
                        <Grid container alignItems="flex-start" style={{ marginRight: '5px' }}>
                            <Grid style={{ flex: '1', position: 'relative' }}>
                                {/*<InquiryBtn >Inquiry</InquiryBtn>*/}
                                <TabMenu
                                    reloadComponent={true}
                                    menu={
                                        currentLanguage === LANGUAGE.KOREAN
                                            ? ['미청산내역', '미체결내역', '거래내역', '종목잔고']
                                            : ['Positions Detail', 'Open Orders', 'Trading History', 'Open Positions']
                                    }
                                    tabWidth={189}
                                    components={[
                                        <PositionDetail key={3} />,
                                        <OpenOrders key={0} />,
                                        <TradingHistory key={1} />,
                                        <OpenPositions key={2} />, 
                                    ]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={'auto'}
                        style={{
                            marginTop: '5px',
                        }}
                    >
                        <OrderTabMenu language={currentLanguage} />

                        <Grid
                            style={{
                                // marginRight: '10px',
                                marginTop: '5px',
                            }}
                        >
                            <TabMenu
                                menu={currentLanguage === LANGUAGE.KOREAN ? ['현황', '디테일'] : ['Margin', 'Detail']}
                                components={[<UserMargin key={0} />, <SymbolDetail key={1} />]}
                                // tabHeight={45}
                                tabWidth={108}
                            />
                        </Grid>

                        {/*<Grid style={{ marginTop: '15px' }}>*/}
                        {/*    <TabMenu*/}
                        {/*        menu={*/}
                        {/*            currentLanguage === LANGUAGE.KOREAN ? ['종목', '관심종목'] : ['Symbol', 'Favorite']*/}
                        {/*        }*/}
                        {/*        components={[<SymbolList key={0} />, <FavoriteList key={1} />]}*/}
                        {/*        tabWidth={210}*/}
                        {/*        tabHeight={45}*/}
                        {/*    />*/}
                        {/*</Grid>*/}
                    </Grid>
                </Grid>
                {/*<MessageBox />*/}
                {/* {process.env.NODE_ENV === 'development' && <DebugKits />} */}

                {/*<DebugHelper isVisible={process.env.NODE_ENV === 'development' ? true : false}>*/}
                {/*</DebugHelper>*/}
            </div>
            <div style={{ height: 6 }}></div>
        </Layout>
    );
};
export default TradingPage;

const InquiryBtn = styled(Button)`
    position: absolute !important;
    top: 7px;
    right: 17px;
    z-index: 1;
    padding: 7px 10px;
    background-color: #191919 !important;
    border: none;
    border-radius: 5px;
    color: #fff !important;
`;

const DebugKits = () => {
    return (
        <div style={{ color: 'white' }}>
            <div style={{ width: 450, border: '1px solid grey' }}>
                <NewOrderFormTest />,
            </div>
            <OrderRegistered />
            <OrderConcluded />
        </div>
    );
};
