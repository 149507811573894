import useAgentWhenSignedIn from '../../../../hooks/useAgentWhenSignedIn';
import { useTypedSelector } from '../../../../states/useTypedSelector';
import { TransactionInputType } from '../../../../types';
import useCurrentLanguage from '../../../../hooks/useCurrentLanguage';
import { useCallback } from 'react';
import {
    GROSS_PL_INDEX_IN_OPEN_POSITIONS,
    LIMIT_PRC_INDEX_IN_OPEN_POSITIONS,
    STOP_PRC_INDEX_IN_OPEN_POSITIONS,
    SZ_CUR_NO_INDEX_IN_OPEN_POSITIONS,
    SZ_CUST_ITEM_INDEX_IN_OPEN_POSITIONS,
    SZ_QUOTE_INDEX_IN_OPEN_POSITIONS,
    SZ_RATE_INDEX_IN_OPEN_POSITIONS,
    TOTAL_PL_INDEX_IN_OPEN_POSITIONS,
} from '../OpenPositions/openPositionsIndices';
import { translateSzPoCode } from '../common/commonUtil';
import formatNumber from '../../../../lib/formatNumber';

type InputPropsType = {
    szAccNo: string | undefined;
    email: string | undefined;
    jwt: string | undefined;
};

const input = ({ szAccNo, email, jwt }: InputPropsType): TransactionInputType => ({
    Header: {
        function: 'D',
        termtype: 'HTS',
        trcode: 't3720',
        userid: email,
        token: jwt,
    },
    Input1: {
        szAccNo: szAccNo,
    },
});

const output2Column = [
    // '체결번호',
    '종목코드2',
    '동일 종목 수량',
    '매매구분',
    '진입환율나중에구현',
    '현재환율',
    '일일손익',
    // '체결환율',
    // '손익(지수)',
    '총손익',
    // '이자율',
    // '수수료',
    '체결수수료',
    // '롤오버',
    '순손익',
    // '주문유형',
    // '만기일',
    // '체결 시각',
    '소수최저자리',
    '반대매매'
];

const output2ColumnInEng = [
    // 'PrOpen_No',
    'Symbol',
    'Lot',
    'Side',
    'Price',
    'Current Price',
    'Price Diffrence',
    // 'OrigOpenPrice',
    // 'TotalPL',
    'Gross P&L',
    // 'Interest',
    // 'Commission',
    'Commission',
    // 'Rollover',
    'NetPL',
    // 'OrdType',
    // 'Due_Date',
    // 'PrOpen_DateTime',
    'Point position',
    'Close position'
];


type ReturnType = {
    data: Array<Array<string | number>>;
    dataColumn: Array<string>;
    isSuccess: boolean;
};

const useData = (): ReturnType => {
    const userReducerData = useTypedSelector((state) => state.userReducer.data);
    const { szAccNo, email, jwt } = userReducerData;

    const { currentLanguage } = useCurrentLanguage();
    const { trResult } = useAgentWhenSignedIn({
        input: input({ szAccNo, email, jwt }),
    });

    const parseData = (data) => {
        if (!data.length) return data;
        const excludeIndex = [0, 7, 8, 10, 11, 13, 15, 16, 17];
        return data.map((d) => {
            const newD = [...d];

            //배열의 첫 번째 값은 유저에게 보여주면 안되므로 삭제.
            // newD.shift();

            // szSide - newD[3] : 079 080 같은 코드들을 의미있는 단어로 번역
            newD[3] = translateSzPoCode(newD[3]);

            return newD.filter((_, idx) => !excludeIndex.includes(idx));
        })
    };

    return {
        data: trResult && trResult.Output2 ? parseData(trResult.Output2) : [],
        dataColumn: currentLanguage === 'ENG' ? output2ColumnInEng : output2Column,
        isSuccess: trResult && trResult.Output1 ? true : false,
    };
};

export default useData;
