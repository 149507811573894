import { useEffect, useState } from 'react';
import formatNumber from '../lib/formatNumber';
import socketService from '../states/socketAgent/SocketService';
import { useTypedSelector } from '../states/useTypedSelector';
import { TransactionInputType } from '../types';
import useUsersData from './useUserData';
import useCurrentLanguage from './useCurrentLanguage';

type UserMarginOutput = {
    data: Array<number | string>;
    dataColumn: Array<string>;
    refetch: () => void;
};

const dataColumnInKOR = [
    '예탁금총액',
    '미결제금액',
    '평가손익',
    '출금가능금액',
    '유지증거금',
    '사용증거금',
    '사용가능자산',
    '마진콜비율',
];

const dataColumnInENG = [
    'Balance',
    'Open Position Margin',
    'Gross P&L',
    'Valuation Equity',
    'Required Order Margin',
    'Maintenance Position Margin',
    'Available Margin',
    'Margin Call rate(%)',
];

const dataColumnInENGArch = [
    'fBalance',
    'fOutstanding',
    'fGrossPL',
    'fEquity',
    'fMarginReq',
    'fMtMargin',
    'fUsableEquity',
    'fUEquityRate',
];

const getTrInfo = ({ szAccNo, email, jwt }): TransactionInputType => {
    return {
        Header: { function: 'D', termtype: 'HTS', trcode: 't3608', userid: email, token: jwt },
        Input1: { szAccNo: szAccNo },
    };
};

const useUserMargin = (): UserMarginOutput => {
    const { szAccNo, email, jwt } = useUsersData();
    const data = useTypedSelector((state) => state.stateReducer[`t3608`]);
    const { currentLanguage } = useCurrentLanguage();

    const refetch = () => {
        if (szAccNo) {
            socketService.sendToAgent(getTrInfo({ szAccNo, email, jwt }));
        }
    };

    useEffect(() => {
        refetch();
    }, [szAccNo]);

    return {
        data: data ? parseData(data.Output2[0]) : [],
        dataColumn: currentLanguage === 'ENG' ? dataColumnInENG : dataColumnInKOR,
        refetch,
    };
};

const parseData = (data) => {
    return data.map((d) => formatNumber(d));
};

export default useUserMargin;
