import React from 'react';
import UserMargin from './UserMargin';

const Index = () => {
    const style = {
        width: '100%',
        height: '385px',
    };

    return <UserMargin style={style} />;
};

export default Index;
