/*=============================
| Typescript : Action types   |
==============================*/
export enum OrderReducerAction {
    UPDATE = 'UPDATE',
    TAB_CLICKED = 'TAB_CLICKED',
    RESET = 'RESET',
    UPDATE_INDEX = 'UPDATE_INDEX'
}

export interface UpdateOrderTabAction {
    type: OrderReducerAction.UPDATE;
    payload: any;
}

export interface TabClickedAction {
    type: OrderReducerAction.TAB_CLICKED;
    payload: {
        index: number;
    };
}

export interface ResetOrderTabAction {
    type: OrderReducerAction.RESET;
}

export interface UpdateIndexActon {
    type: OrderReducerAction.UPDATE_INDEX
    payload: number;
}

type OrderReducerActions = UpdateOrderTabAction | TabClickedAction | ResetOrderTabAction | UpdateIndexActon;

/*=============================
| Javascript : Action craetors |
==============================*/
export const UPDATE_ORDER_TAB = (payload): UpdateOrderTabAction => ({
    type: OrderReducerAction.UPDATE,
    payload: payload,
});

export const TAB_CLICKED = (payload: { index: number }): TabClickedAction => ({
    type: OrderReducerAction.TAB_CLICKED,
    payload: payload,
});

export const RESET = (): ResetOrderTabAction => ({
    type: OrderReducerAction.RESET
});

export const UPDATE_INDEX = (payload: number): UpdateIndexActon => ({
    type: OrderReducerAction.UPDATE_INDEX,
    payload: payload,
})
/*=============================
| Typescript :  state          |
==============================*/

interface OrderState {
    index: number;
    data: any;
    triggeredBy: 'open-orders' | 'open-positions' | undefined;
    stopOrLimit: 'stop' | 'limit ' | undefined;
}

const defaultState = {
    index: 0,
    data: {},
    triggeredBy: undefined,
    stopOrLimit: undefined,
};

export const orderReducer = (state: OrderState = defaultState, action: OrderReducerActions): OrderState => {
    switch (action.type) {
        case OrderReducerAction.UPDATE:
            return { ...action.payload };
        case OrderReducerAction.TAB_CLICKED:
            return { ...state, data: {}, index: action.payload.index };
        case OrderReducerAction.RESET: {
         console.log('유저가 RESET');
            return defaultState;
        }
        case OrderReducerAction.UPDATE_INDEX: {
            return {
                ...state,
                index: action.payload,
                data: {},
            }
        }
        default:
            return state;
    }
};
