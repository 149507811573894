import React from 'react';

interface IProps {
    width?: number;
    height?: number;
    fill?: string;
}
const Star = ({ width = 22, height = 22, fill = '#393B7C' }: IProps) => {
    return (
        <svg width={width} height={height} viewBox="0 0 22 22" fill="none">
            <path
                d="M11.0066 17.8579C11.0066 18.2455 10.7827 18.5976 10.4323 18.7619C10.2968 18.826 10.1516 18.857 10.0073 18.857C9.77891 18.857 9.55261 18.7785 9.36983 18.6269L3.90802 14.101H0.999054C0.447387 14.1015 0 13.6538 0 13.1022V9.12181C0 8.5699 0.447387 8.12252 0.999054 8.12252H3.90825L9.37006 3.59662C9.66848 3.34928 10.0823 3.29655 10.4325 3.46208C10.7827 3.62642 11.0069 3.97875 11.0069 4.36607L11.0066 17.8579ZM14.8461 16.2968C14.8217 16.2984 14.7983 16.2994 14.7742 16.2994C14.5103 16.2994 14.2559 16.1951 14.0679 16.0069L13.9343 15.8728C13.5838 15.5231 13.5427 14.9691 13.8378 14.5713C14.586 13.5626 14.9808 12.3668 14.9808 11.1123C14.9808 9.76309 14.5325 8.49755 13.6841 7.45238C13.3611 7.05512 13.3909 6.47816 13.7529 6.11637L13.8863 5.98277C14.0858 5.78319 14.3533 5.67442 14.6427 5.69168C14.9246 5.70587 15.1878 5.83852 15.3665 6.05702C16.5434 7.49707 17.1651 9.24548 17.1651 11.1126C17.1651 12.8515 16.6153 14.5068 15.5748 15.8986C15.401 16.1306 15.1353 16.2762 14.8461 16.2968ZM18.9766 19.3843C18.7959 19.5978 18.5346 19.726 18.2549 19.7378C18.2412 19.7383 18.2272 19.7387 18.2131 19.7387C17.9485 19.7387 17.6945 19.6342 17.5065 19.4462L17.3753 19.315C17.0085 18.9485 16.9837 18.3623 17.3171 17.9655C18.9284 16.0492 19.816 13.6155 19.816 11.1123C19.816 8.50866 18.8657 6.00216 17.1407 4.05465C16.791 3.65929 16.8087 3.0608 17.1809 2.68719L17.3119 2.55595C17.5063 2.36063 17.7588 2.25304 18.0485 2.26227C18.3235 2.27007 18.5836 2.39161 18.7664 2.59733C20.8515 4.94493 22 7.96929 22 11.1123C22.0005 14.1365 20.9267 17.0743 18.9766 19.3843Z"
                fill={fill}
            />
        </svg>
    );
};

export default Star;
