import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import BaseTable from './../../common/BaseTable';
import LogInRequired from './../../common/LogInRequired';
import useData from './useData';
// import { style, mobileStyle } from '../common/tableStyle';
import useScreenSize from '../../../hooks/useScreenSize';
import useUserTabStyle from '../../../hooks/useUserTabStyle';
import {useLocation} from 'react-router-dom';

export default function Index({tradingHistoryData, dataColumn}) {
    const location = useLocation();
    const {sub_path} =  location.state;
    const { style, mobileStyle } = useUserTabStyle();
    // const [date, setDate] = useState({fromDate: from, toDate: to});

    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [availablePages, setAvailablePages] = useState();
    const [limit, setLimit] = useState(15);

    const isLoggedIn = useSelector((state) => state.userReducer.isLoggedIn);
    const { isMobile } = useScreenSize();

    // let { data: tradingHistoryData, dataColumn } = useData({
    //     date: {fromDate: from, toDate: to}, subPath: sub_path
    // });
    const tradingHistroyProps = {
        data: tradingHistoryData,
        dataColumn,
        tableFor: 'trading-history',
    }
     



    /*
    Calendar 사용할때 코드
    */
    // useEffect(() => {
    //     let newData = tradingHistoryData.slice(0, limit);
    //     setAvailablePages(Math.ceil(tradingHistoryData.length / limit));
    //     setData(newData);
    //     setCurrentPage(1);
    // }, [tradingHistoryData.length]);

    // useEffect(() => {
    //     let start = limit * (currentPage - 1);
    //     let end = start + limit;
    //     let newData = tradingHistoryData.slice(start, end);
    //     setData(newData);
    // }, [currentPage]);

    const handleDateChange = (date) => {
        setDate(date);
    };

    const handlePage = (value) => () => {
        // if (value === -1) {
        //     currentPage !== 1 && setCurrentPage(currentPage + value);
        // } else if (value === 1) {
        //     currentPage !== availablePages && setCurrentPage(currentPage + value);
        // }
    };



    const PcStyle = {
        ...style,
        maxHeight: style.maxHeight,
    };
    const styleProps = isMobile ? mobileStyle : PcStyle;

    const logInStyleProps = isMobile
        ? {
              width: '100%',
              height: '200px',
          }
        : {};

    return isLoggedIn ? (
        <>
            <BaseTable {...tradingHistroyProps} {...styleProps} />
        </>
    ) : (
        <LogInRequired {...logInStyleProps} />
    );
}
