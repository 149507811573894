import React from 'react';
import styled, { css } from 'styled-components';

import { Wrap } from './SectionWrap';
import LandingSectionTitle from './LandingSectionTitle';
import LandingSectionSubTitle from './LandingSectionSubTitle';
import LandingSectionContents from './LandingSectionContents';
import { ImageWrap } from './LandingSectionImageWrap';
import { ButtonWrap } from './LandingSectionButtonWrap';

import OutlinedButton from './OutlinedButton';
import useScreenSize from '../../../hooks/useScreenSize';
import SECTION04 from '../../../assets/landing/section4@2x.png';
import { useHistory } from 'react-router-dom';

const Section04 = () => {
    const history = useHistory();
    const { isMobile } = useScreenSize();
    return (
        <Section mobile={isMobile}>
            <div className="inner">
                {isMobile && (
                    <LandingSectionImageWrap mobile={isMobile}>
                        <img
                            src={SECTION04}
                            style={isMobile ? { width: '225px', height: '220px' } : { width: '410px', height: '400px' }}
                            alt="section4"
                        />
                    </LandingSectionImageWrap>
                )}

                <div className="contents">
                    <LandingSectionTitle>
                        {isMobile ? (
                            <>
                                All time operating
                                <br />
                                security system on
                            </>
                        ) : (
                            <>All time operating security system on</>
                        )}
                    </LandingSectionTitle>
                    <LandingSectionSubTitle>Asset cannot be hacked</LandingSectionSubTitle>
                    <LandingSectionContents>
                        Tighter security measures were installed to better protect
                        <br /> wallet of users from cyber attacks.
                    </LandingSectionContents>
                    <LandingSectionButtonWrap mobile={isMobile}>
                        <OutlinedButton onClick={() => history.push('/trade')}>Start Trading</OutlinedButton>
                    </LandingSectionButtonWrap>
                </div>
                {!isMobile && (
                    <LandingSectionImageWrap mobile={isMobile}>
                        <img src={SECTION04} style={{ width: '410px', height: '400px' }} alt="section4" />
                    </LandingSectionImageWrap>
                )}
            </div>
        </Section>
    );
};

export default Section04;

const Section = styled(Wrap)`
    background: #f3f6f8;
    ${({ mobile }) =>
        mobile &&
        css`
            .contents {
                width: 100%;
                text-align: right;
            }
        `}
`;

const LandingSectionImageWrap = styled(ImageWrap)`
    margin-left: 200px;

    ${({ mobile }) =>
        mobile &&
        css`
            height: 220px;
            margin-left: 0;
            & > img {
                height: auto !important;
                position: absolute !important;
                top: unset;
                left: 10px;
                transform: unset;
            }
        `}
`;

const LandingSectionButtonWrap = styled(ButtonWrap)`
    ${({ mobile }) =>
        mobile &&
        css`
            justify-content: flex-end;
            width: auto;
            margin: 0;
        `}
`;
