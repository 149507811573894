const fontSizes = {
    content: '12px',
    mediumContent: '14px',
    largeContent: '16px',
    tabHeader: '14px',
    NavigationMenu: '18px',
    NavigationSubMenu: '16px',
    orderTabMessage: '13px',
};

const tabMenu = {
    tabMenuBackgroundColor: 'rgba(84, 97, 189, 0.13);',
    scrollBarWidth: '3px',
    tabMenuBorderColor: '#5461BD',
    rowHeight: '40px',
    tabMenuHeight: '37px',
};

const orderTabMenu = {
    height: '450px',
    divPadding: '14px 0',
    containerPadding: '14px 20px 10px 20px',
};

const colors = {
    black: '#000000',
    white: '#FFFFFF',
    blue: '#41DA78',
    red: '#F8585A',
    orange: '#FFAB2E',
    bodyBackgroundColor: '#000000',
    //original Futbit
    inputFieldColor: '#33353B',
    primaryColor: '#1E1F23',
    secondaryColor: '#33353B',

    normalTextColor: '#FFFFFF',
    dimmerTextColor: '#A1A1A1',
    tabHeaderTextColor: 'rgba(255, 255, 255, 0.6)',
    tabHeaderTextOnFocusColor: '#FFAB2E',
};

const landingSectionBackground = {
    white: '#FFF',
    blue: '#F3F6F8',
    review: '#EFF3F5',
};

const theme = {
    colors,
    fontSizes,
    tabMenu,
    orderTabMenu,
    landingSectionBackground,
};

export default theme;
