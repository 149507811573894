import React from 'react';
import Layout from '../../../components/layout';
import Chart from '../../../components/client/Chart/Chart';

const TestPage = () => {
  return(
    <Layout>
      <Chart/>
    </Layout>
  )
};

export default TestPage
