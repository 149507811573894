import React, {useEffect} from 'react';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { IconButton } from '@material-ui/core';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';

import { DELETE_TR, RESET_TR } from '../../states/reducers/agentReducer';
import socketService from '../../states/socketAgent/SocketService';
import { useTypedSelector } from '../../states/useTypedSelector';
import { USER_LOG_OUT } from '../../states/reducers/userReducer';

import Close from '../svgs/Close';
import useUsersData from '../../hooks/useUserData';
import { ContainedButton, RegisterWrap } from './Navigation';
import useScreenSize from '../../hooks/useScreenSize';
import LogoutButton from '../common/LogoutButton';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'absolute',
            top: 0,
            right: 0,
            width: '100%',
            height: '100%',
            minHeight: '100vh',
            backgroundColor: '#2F4C68',
            padding: '16px',
        },
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'flex-start',
            justifyContent: 'space-between',
            height: '90%',
        },
        navigation: {},
        nested: {
            marginLeft: '15px',
        },
        listItem: {
            width: '100%',
            padding: '16px',
            borderBottom: '1px solid #203C57',
        },
        listItemText: {
            '& > span': {
                color: '#fff',
                fontSize: '15px',
                lineHeight: '17px',
            },
        },
        listItemTextIn: {
            '& > span': {
                color: '#F49405',
                fontSize: '15px',
                lineHeight: '17px',
            },
        },
    }),
);

interface IProps extends RouteComponentProps {
    onClose: () => void;
}
const MobileNavigation = ({ onClose }: IProps) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { isMobile } = useScreenSize();

    const szAccNo = useTypedSelector((state) => state.userReducer.data.szAccNo);
    const  email = useTypedSelector((state) => state.userReducer.data.email);
    const loout = useTypedSelector((state) => state.stateReducer.loout);

    const [loading, setLoading] = React.useState(false);
    const [openWallet, setOpenWallet] = React.useState(false);
    const [openEvent, setOpenEvent] = React.useState(false);
    const [openTrade, setOpenTrade] = React.useState(false);
    const [openSupport, setOpenSupport] = React.useState(false);
    const [openMetaverse, setOpenMetaverse] = React.useState(false);
    const { isLoggedIn } = useUsersData();

    useEffect(() => {
        return () => {
            dispatch(DELETE_TR({ key: `loout`, data: [] }))
        }
    }, [])

    useEffect(() => {
        if (loout) {
            const { flag, data } = loout.Message;
            switch (flag) {
                case 'E':
                    alert(data);
                    // setLoading(false);
                    break;
                case '0':
                    dispatch(USER_LOG_OUT());
                    // reset시 order book도 사라짐?
                    // dispatch(RESET_TR());
                    setLoading(false);
                    break;
                default:
                    alert(data);
            }
        }
    }, [loout, loading]);

    const handleClickWallet = () => {
        setOpenWallet((state) => !state);
    };

    const handleClickEvent = () => {
        setOpenEvent((state) => !state);
    };

    const handleClickTrade = () => {
        setOpenTrade((state) => !state);
    };

    const handleClickSupport = () => {
        setOpenSupport((state) => !state);
    };

    const handleClickMetaverse = () => {
        setOpenMetaverse((state) => !state);
    };

    const handleClickConstruction = () => {
        alert('Service is being prepared.');
    };

    const handleSubmit = () => {
        const liveTrCodes = ['96', '95', '98'];
        liveTrCodes.map(v => {
            socketService.sendToAgent({
                Header: {
                    function: 'U', // 응답시 'F'
                    termtype: 'HTS',
                    trcode: v,
                },
                Input1: {
                    // 응답시 "Input" 없슴
                    Key1: szAccNo, // 계좌번호(key)
                },
            })
        })
        socketService.sendToAgent({
            Header: { function: 'D', termtype: 'HTS', trcode: 'loout' },
            Input1: { 
                szMemberNo: "000",
                szCustId: email,
                cUserLevel : "4",
                szIPAddress : "",
                cFlag : "1"
            },
        })
        setLoading(true);
    };

    const wallet_loaction = {
        location_1: {
            pathname: '/wallet',
            state: { sub_path: '/asset', szAccNo: szAccNo },
        },
        location_2: {
            pathname: '/wallet',
            state: { sub_path: '/history', szAccNo: szAccNo },
        },
        location_3: {
            pathname: '/wallet',
            state: { sub_path: '/convert', szAccNo: szAccNo },
        },
        location_4: {
            pathname: '/wallet',
            state: { sub_path: '/transfer', szAccNo: szAccNo },
        },
        location_5: {
            pathname: '/wallet',
            state: { sub_path: '/deposit_withdraw', szAccNo: szAccNo },
        },
    };

    const trade_loaction = {
        location_1: {
            pathname: '/execution',
            state: { sub_path: '/list', szAccNo: szAccNo },
        },
        location_2: {
            pathname: '/execution',
            state: { sub_path: '/detail', szAccNo: szAccNo },
        },
        location_3: {
            pathname: '/execution',
            state: { sub_path: '/close', szAccNo: szAccNo },
        },
    };

    return (
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
                <IconButton onClick={onClose}>
                    <Close />
                </IconButton>
            }
            className={classes.root}
        >
            <div className={classes.container}>
                <div className={classes.navigation}>
                    <ListItem button className={classes.listItem}>
                        <NavLink to="/trade">
                            <ListItemText className={classes.listItemText} primary="Futures Trade" />
                        </NavLink>
                    </ListItem>
                    <ListItem button onClick={handleClickWallet} className={classes.listItem}>
                        <ListItemText
                            className={openWallet ? classes.listItemTextIn : classes.listItemText}
                            primary="Wallet"
                        />

                        {openWallet ? <ExpandLess fill={'#fff'} /> : <ExpandMore fill={'#fff'} />}
                    </ListItem>
                    <Collapse in={openWallet} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem button className={classes.nested}>
                                <NavLink to={wallet_loaction.location_1} onClick={onClose}>
                                    <ListItemText className={classes.listItemText} primary="My Asset" />
                                </NavLink>
                            </ListItem>
                            <ListItem button className={classes.nested}>
                                <NavLink to={wallet_loaction.location_2} onClick={onClose}>
                                    <ListItemText className={classes.listItemText} primary="History" />
                                </NavLink>
                            </ListItem>
                            <ListItem button className={classes.nested}>
                                <NavLink to={wallet_loaction.location_3} onClick={onClose}>
                                    <ListItemText className={classes.listItemText} primary="Convert" />
                                </NavLink>
                            </ListItem>
                            {/* <ListItem button className={classes.nested}>
                                <NavLink to={wallet_loaction.location_4} onClick={onClose}>
                                    <ListItemText className={classes.listItemText} primary="Transfer" />
                                </NavLink>
                            </ListItem> */}
                            <ListItem button className={classes.nested}>
                                <NavLink to={wallet_loaction.location_5} onClick={onClose}>
                                    <ListItemText className={classes.listItemText} primary="Deposit" />
                                </NavLink>
                            </ListItem>
                        </List>
                    </Collapse>

                    <ListItem button onClick={handleClickTrade} className={classes.listItem}>
                        <ListItemText
                            className={openSupport ? classes.listItemTextIn : classes.listItemText}
                            primary="Trade History"
                        />

                        {openTrade ? <ExpandLess fill={'#fff'} /> : <ExpandMore fill={'#fff'} />}
                    </ListItem>

                    <Collapse in={openTrade} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem button className={classes.nested}>
                                <NavLink to={trade_loaction.location_1} onClick={onClose}>
                                    <ListItemText className={classes.listItemText} primary="Execution List" />
                                </NavLink>
                            </ListItem>
                            <ListItem button className={classes.nested}>
                                <NavLink to={trade_loaction.location_2} onClick={onClose}>
                                    <ListItemText className={classes.listItemText} primary="Order/Execution Detail" />
                                </NavLink>
                            </ListItem>
                            <ListItem button className={classes.nested}>
                                <NavLink to={trade_loaction.location_3} onClick={onClose}>
                                    <ListItemText className={classes.listItemText} primary="Close Execution List" />
                                </NavLink>
                            </ListItem>
                        </List>
                    </Collapse>

                    <ListItem button onClick={handleClickSupport} className={classes.listItem}>
                        <ListItemText
                            className={openSupport ? classes.listItemTextIn : classes.listItemText}
                            primary="Support/Exchange"
                        />

                        {openSupport ? <ExpandLess fill={'#fff'} /> : <ExpandMore fill={'#fff'} />}
                    </ListItem>
                    <Collapse in={openSupport} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                        <ListItem button className={classes.nested} onClick={onClose}>
                                <NavLink to="/guide/deposit">
                                    <ListItemText className={classes.listItemText} primary="Depsoit" />
                                </NavLink>
                            </ListItem>
                            <ListItem button className={classes.nested} onClick={onClose}>
                                <NavLink to="/guide/withdraw">
                                    <ListItemText className={classes.listItemText} primary="Withdraw" />
                                </NavLink>
                            </ListItem>
                            <ListItem button className={classes.nested} onClick={onClose}>
                                <NavLink to="/guide/submit">
                                    <ListItemText className={classes.listItemText} primary="Submit Request" />
                                </NavLink>
                            </ListItem>
                            <ListItem button className={classes.nested} onClick={onClose}>
                                <NavLink to="/guide">
                                    <ListItemText className={classes.listItemText} primary="User Guide" />
                                </NavLink>
                            </ListItem>
                        </List>
                    </Collapse>


                    <ListItem button onClick={handleClickMetaverse} className={classes.listItem}>
                        <ListItemText
                            className={openMetaverse ? classes.listItemTextIn : classes.listItemText}
                            primary="Metaverse/Trader"
                        />

                        {openMetaverse ? <ExpandLess fill={'#fff'} /> : <ExpandMore fill={'#fff'} />}
                    </ListItem>
                    <Collapse in={openMetaverse} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                        <ListItem button className={classes.nested} onClick={onClose}>
                                <NavLink to="/metaverse/avatar-trader">
                                    <ListItemText className={classes.listItemText} primary="Avatar Traders" />
                                </NavLink>
                            </ListItem>
                            <ListItem button className={classes.nested} onClick={onClose}>
                                <NavLink to="/metaverse/my-avatars">
                                    <ListItemText className={classes.listItemText} primary="My Avatars" />
                                </NavLink>
                            </ListItem>
                            <ListItem button className={classes.nested} onClick={onClose}>
                                <NavLink to="/metaverse/avatars-detail">
                                    <ListItemText className={classes.listItemText} primary="Avatars Detail" />
                                </NavLink>
                            </ListItem>
                            <ListItem button className={classes.nested} onClick={onClose}>
                                <NavLink to="/metaverse/leaders-avatars">
                                    <ListItemText className={classes.listItemText} primary="Leaders Avatars" />
                                </NavLink>
                            </ListItem>
                        </List>
                    </Collapse>
                </div>

                <RegisterWrap mobile={true}>
                    {isLoggedIn ? (
                        // <Menu to="/mobile/signin" style={{ margin: 0 }}>
                        //     <LogoutButton onClick={handleSubmit} />
                        // </Menu>
                        <LogoutButton onClick={handleSubmit} />
                    ) : (
                        <>
                            <Menu to="/mobile/signin" activeStyle={{ fontWeight: 'bold' }}>
                                Login
                            </Menu>
                            <Menu to="/mobile/signup" style={{ margin: 0 }}>
                                <ContainedButton mobile={isMobile} variant="contained">
                                    Register
                                </ContainedButton>
                            </Menu>
                        </>
                    )}
                </RegisterWrap>
            </div>
        </List>
    );
};

export default withRouter(MobileNavigation);

const Menu = styled(NavLink)`
    width: 100%;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    text-decoration: none;
`;
