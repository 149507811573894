import React from 'react';
import ExchangeHistory from './ExchangeHistory';

const index = () => {
    return (
        <div>
            <ExchangeHistory />
        </div>
    );
};

export default index;
