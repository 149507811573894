import styled from 'styled-components';

const ScrollBar = styled.div`
    &::-webkit-scrollbar-track {
        background-color: ${({ theme }) => theme.colors.primaryColor};
    }

    &::-webkit-scrollbar {
        width: 7px;
        height: 7px;
        background-color: ${({ theme }) => theme.colors.secondaryColor};
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        width: 7px;
        background-color: ${({ theme }) => theme.colors.secondaryColor};
    }

    ::-webkit-scrollbar-corner {
        background-color: ${({ theme }) => theme.colors.primaryColor};
    }
`;
export default ScrollBar;
