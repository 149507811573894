export type ScreenSizeType = 'xs' | 'sm' | 'md' | 'xl' | undefined;
export type LanguageType = 'KOR' | 'ENG';

/*=============================
| Typescript : Action types   |
==============================*/
export enum MarginAndLeverageActionType {
    SET_MARGIN_TYPE = 'SET_MARGIN_TYPE',
    SET_LEVERAGE = 'SET_LEVERAGE_TYPE',
}

export interface SetMargin {
    type: MarginAndLeverageActionType.SET_MARGIN_TYPE;
    payload: string;
}

export interface SetLeverage {
    type: MarginAndLeverageActionType.SET_LEVERAGE;
    payload: string;
}

type MarginAndLeverageActions = SetMargin | SetLeverage;

/*=============================
| Javascript : Action craetors |
==============================*/

export const SET_MARGIN_TYPE = (payload): MarginAndLeverageActions => ({
    type: MarginAndLeverageActionType.SET_MARGIN_TYPE,
    payload,
});

export const SET_LEVERAGE = (payload): MarginAndLeverageActions => ({
    type: MarginAndLeverageActionType.SET_LEVERAGE,
    payload,
});

interface MarginAndLeverageState {
    margin_type: string;
    leverage: string;
}

const defaultState = {
    margin_type: '1',
    leverage: '10',
};

export const marginAndLeverageReducer = (
    state: MarginAndLeverageState = defaultState,
    action: MarginAndLeverageActions,
): MarginAndLeverageState => {
    switch (action.type) {
        case MarginAndLeverageActionType.SET_MARGIN_TYPE:
            return {
                ...state,
                margin_type: action.payload,
            };
        case MarginAndLeverageActionType.SET_LEVERAGE:
            return {
                ...state,
                leverage: action.payload,
            };

        default:
            return state;
    }
};
