export const SZ_CUR_NO_INDEX_IN_OPEN_POSITIONS =1;
export const SZ_SIDE_INDEX_IN_OPEN_POSITIONS =2;
export const SZ_RATE_INDEX_IN_OPEN_POSITIONS = 3;
export const F_LOT_INDEX_IN_OPEN_POSITIONS =4;
export const SZ_QUOTE_INDEX_IN_OPEN_POSITIONS = 5;
export const STOP_PRC_INDEX_IN_OPEN_POSITIONS = 6;
export const LIMIT_PRC_INDEX_IN_OPEN_POSITIONS = 7;
export const TOTAL_PL_INDEX_IN_OPEN_POSITIONS = 8;
export const GROSS_PL_INDEX_IN_OPEN_POSITIONS = 9;
export const SZ_CUST_ITEM_INDEX_IN_OPEN_POSITIONS = 13;
export const SZ_STOP_ITEM_INDEX_IN_OPEN_POSITIONS = 14;
export const SZ_LIMIT_ITEM_INDEX_IN_OPEN_POSITIONS = 15;
