import React, { useEffect, useState } from 'react';
import { ENGLISH, KOREAN } from '../../../constants/Language';
import useCurrentLanguage from '../../../hooks/useCurrentLanguage';
import { withRouter } from 'react-router';
import styled, { css } from 'styled-components';
import { Grid, TextField } from '@material-ui/core';
import useSignIn from '../../../hooks/useSignIn';
import PageNameList from '../../../constants/PageNameLIst';
import CustomInput, { TypeEnum } from '../../common/CustomInput';
import Layout from '../../layout';
import useScreenSize from '../../../hooks/useScreenSize';

const landingPageText = (language) => {
    switch (language) {
        case ENGLISH:
            return 'Trustworthy Trading Platform';
        case KOREAN:
            return '믿을수 있는 가상화폐 거래소';
        default:
            return '믿을수 있는 가상화폐 거래소';
    }
};
const signUpText = (language) => {
    switch (language) {
        case ENGLISH:
            return 'Register';
        case KOREAN:
            return '회원가입';
        default:
            return '회원가입';
    }
};
const signInText = (language) => {
    switch (language) {
        case ENGLISH:
            return 'Login';
        case KOREAN:
            return '로그인';
        default:
            return '로그인';
    }
};
const emailText = (language) => {
    switch (language) {
        case ENGLISH:
            return 'Email';
        case KOREAN:
            return '이메일 주소';
        default:
            return '이메일 주소';
    }
};

const passwordText = (language) => {
    switch (language) {
        case ENGLISH:
            return 'Password';
        case KOREAN:
            return '비밀번호';
        default:
            return '비밀번호';
    }
};
const otpTokenText = (language) => {
    switch (language) {
        case ENGLISH:
            return 'OTP Token';
        case KOREAN:
            return 'OTP 토큰';
        default:
            return 'OTP 토큰';
    }
};

const SignIn = ({ history }) => {
    const { isMobile } = useScreenSize();
    const { currentLanguage } = useCurrentLanguage();
    const [input, setInput] = useState({
        password: '',
        email: '',
        otp_token: '',
    });

    const { handleSignIn, handleDemoLogin, error, success, data } = useSignIn();

    useEffect(() => {
        if (success) {
            history.push(`${PageNameList.TRADE}`);
            localStorage.setItem('elpist', JSON.stringify({ isLoggedIn: true }));
        }
    }, [success]);

    const handleChange = (target) => (e) => {
        setInput({
            ...input,
            [target]: e.target.value,
        });
    };

    const handleSubmit = () => {
        handleSignIn({ ...input });
    };

    const handleRedirect = () => {
        history.push(`${PageNameList.MOBILE_SIGNUP}`);
    };

    const handleDemoSignInAndRedirect = () => {
        handleDemoLogin();
        history.push(`${PageNameList.TRADE}?debug=true`);
    };

    return (
        <Layout theme="light">
            <Wrapper container justify="center" mobile={isMobile}>
                <Title mobile={isMobile}>Login</Title>
                <Container mobile={isMobile}>
                    <InputWrapper>
                        {error && <ErrorMsg>{error}</ErrorMsg>}
                        <div style={{ height: 4 }}></div>

                        <CustomInput
                            label={emailText(currentLanguage)}
                            onChange={handleChange('email')}
                            type={TypeEnum.Text}
                            color="black"
                        />
                        <div style={{ height: 4 }}></div>
                        <CustomInput
                            label={passwordText(currentLanguage)}
                            onChange={handleChange('password')}
                            type={TypeEnum.Password}
                            color="black"
                        />

                        <LoginButton onClick={handleSubmit}>{signInText(currentLanguage)}</LoginButton>
                        {/*<LoginButton onClick={handleDemoSignInAndRedirect}>{'강남 데모 로그인'}</LoginButton>*/}
                    </InputWrapper>
                </Container>
                <LoginButton onClick={handleRedirect}>{signUpText(currentLanguage)}</LoginButton>
            </Wrapper>
        </Layout>
    );
};

export default withRouter(SignIn);

const Wrapper = styled(Grid)<{ mobile: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    ${({ mobile }) =>
        mobile &&
        css`
            padding: 0 16px;
        `}
`;

const Container = styled.div<{ mobile: boolean }>`
    max-width: 1200px;
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding: 20px 0;
    border: 1px solid #d0d0d0;
    box-sizing: border-box;
    border-radius: 4px;
    ${({ mobile }) =>
        mobile &&
        css`
            padding: 30px 16px;
        `}
`;

const Title = styled.div<{ mobile: boolean }>`
    font-weight: bold;
    font-size: 32px;
    line-height: 46px;
    text-align: center;

    color: #323232;
    margin-bottom: 40px;
    ${({ mobile }) =>
        mobile &&
        css`
            font-size: 18px;
            line-height: 26px;
            text-align: center;
        `}
`;

const InputWrapper = styled.div`
    max-width: 380px;
    width: 100%;
    margin: 0 auto;
`;

const LoginButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 380px;
    width: 100%;
    height: 48px;
    cursor: pointer;
    margin-top: 5px;
    background: linear-gradient(90.91deg, #f3ad4c 0%, #e69536 100%);
    border-radius: 4px;
    font-weight: bold;
    font-size: 15px;
    line-height: 22px;
    /* identical to box height */

    text-align: center;

    color: #ffffff;
`;

const ErrorMsg = styled.div`
    color: red;
`;
