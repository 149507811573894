import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import useScreenSize from '../../../hooks/useScreenSize';

interface IProps {
    children: string | ReactNode;
}

const SectionWrap = ({ children }: IProps) => {
    const { isMobile } = useScreenSize();
    return (
        <Wrap mobile={isMobile}>
            <div className="inner">{children}</div>
        </Wrap>
    );
};

export default SectionWrap;

export const Wrap = styled.div<{ mobile: boolean }>`
    width: 100%;
    position: relative;
    padding: 40px 50px;
    overflow: hidden;
    .inner {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
    }
    ${({ mobile }) =>
        mobile &&
        css`
            width: 100%;
            height: auto;
            padding: 60px 16px;
            .inner {
                display: flex;
                width: 100%;
                max-width: unset;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
            }
        `}
`;
