import React from 'react';
import Layout from '../../../components/layout';
import Hero from './Hero';

const index = () => {
    return (
        <Layout>
            <Hero />
        </Layout>
    );
};

export default index;
