import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { widget } from './lib/charting_library';
import useDatafeed from './useDatafeed';
import styled from 'styled-components';
import useSymbolList from '../../../hooks/useSymbolList';
import socketService from '../../../states/socketAgent/SocketService';
import './custom-chart-css.css';
const UP_COLOR = '#41DA78';
const DOWN_COLOR = '#F8585A';
const PRIMARY_COLOR = '#1E1F23';
const LINE_COLOR = '#33353B';

moment.tz.setDefault(process.env.REACT_APP_TIME_ZONE);


function Chart(props) {
    const chartDataBody = useSelector((state) => state.chartState.chartData_t9731);
    const chartData = chartDataBody.data;

    const { currentSymbol } = useSymbolList();
    const [interval, setInterval] = useState('1');
    // const dispatch = useDispatch();

    let datafeed = useDatafeed(chartData, currentSymbol);

    useEffect(() => {
        const widgetOptions = {
            symbol: props.symbol,
            datafeed: datafeed,
            interval: interval,
            container_id: props.containerId,
            library_path: props.libraryPath,

            locale: 'en',
            disabled_features: ['use_localstorage_for_settings'],
            enabled_features: ['study_templates'],
            charts_storage_url: props.chartsStorageUrl,
            charts_storage_api_version: props.chartsStorageApiVersion,
            client_id: props.clientId,
            user_id: props.userId,
            fullscreen: props.fullscreen,
            autosize: props.autosize,
            studies_overrides: props.studiesOverrides,
            timezone: process.env.REACT_APP_TIME_ZONE,
            theme: 'dark',
            toolbar_bg: PRIMARY_COLOR,
            // toolbar_bg: 'transparent',
            loading_screen: {
                backgroundColor: PRIMARY_COLOR,
            },
            overrides: {
                //experimental
                // theme: PRIMARY_COLOR,
                'paneProperties.background': PRIMARY_COLOR,
                'paneProperties.vertGridProperties.color': LINE_COLOR,
                'paneProperties.horzGridProperties.color': LINE_COLOR,

                // 'symbolWatermarkProperties.color': '#000000',
                // 'symbolWatermarkProperties.transparency': 100,
                'scalesProperties.textColor': LINE_COLOR,
                // "symbolWatermarkProperties.transparency": 90,
                // "scalesProperties.textColor" : "#AAA",
                'scalesProperties.lineColor': LINE_COLOR,
                'scalesProperties.backgroundColor': UP_COLOR,

                'mainSeriesProperties.candleStyle.upColor': UP_COLOR,
                'mainSeriesProperties.candleStyle.downColor': DOWN_COLOR,
                'mainSeriesProperties.candleStyle.borderUpColor': UP_COLOR,
                'mainSeriesProperties.candleStyle.borderDownColor': DOWN_COLOR,
                'mainSeriesProperties.candleStyle.wickUpColor': UP_COLOR,
                'mainSeriesProperties.candleStyle.wickDownColor': DOWN_COLOR,

                'mainSeriesProperties.baselineStyle.baselineColor': 'rgba( 117, 134, 150, 1)',
            },
        };

        let tvWidget = new widget(widgetOptions);

        tvWidget.onChartReady(() => {
            tvWidget.addCustomCSSFile('./custom-chart-css.css');

            tvWidget.headerReady().then((data) => {
                //do something here
            });

            tvWidget
                .activeChart()
                .onIntervalChanged()
                .subscribe(null, (interval, timeframeObj) => {
                    const nMinTerm = {
                        '1' : 1,
                        '5' : 5,
                        '10' : 10,
                        '60' : 60,
                        '1D' : 1,
                        '1W' : 7,
                        '1M' : 30
                    }
                    const info = {
                        Header: {
                            function: 'D',
                            termtype: 'HTS',
                            trcode: 't9731',
                            trid: '1',
                        },
                        Input1: {
                            szCurNo: currentSymbol,
                            cTermDiv: '2',
                            szCritDate: '99999999',
                            szCritTime: moment().format('HHmmssSSS'),//'999999999',
                            nMinTerm: nMinTerm[interval],
                            nReqCnt: '500',
                        },
                    };
                    if (['1D', '1W', '1M'].includes(interval)) {
                        setInterval(interval);
                        info.Input1.cTermDiv = '3';
                    } 
                    setInterval(interval);
                    socketService.sendToAgent(info);
                    chartDataBody.loading = true;
                });
        });

        return () => {
            datafeed = null;
            tvWidget.remove();
            tvWidget = null;

            chartDataBody.loading = true;
            sessionStorage.bulkEnd = null
            sessionStorage.chartEnd = null
        };
    }, [chartData, interval]);

    useEffect(() => {
        setInterval('1');

        chartDataBody.loading = true;
        sessionStorage.bulkEnd = null
        sessionStorage.chartEnd = null
        
    }, [currentSymbol]);

    return (
        <>
            <ChartCont id={props.containerId} className={'chart_container'} style={{ height: '100%' }} />
        </>
    );
}

Chart.defaultProps = {
    symbol: 'BCE2009Q03BU',
    interval: 'D',
    containerId: 'chart_container',
    libraryPath: '/charting_library/',
    chartsStorageUrl: 'https://saveload.tradingview.com',
    chartsStorageApiVersion: '1.1',
    clientId: 'tradingview.com',
    userId: 'public_user_id',
    fullscreen: false,
    autosize: true,
    studiesOverrides: {},
};

export default Chart;

const ChartCont = styled.div`
    .chart-page {
        --tv-color-pane-background: #ffffff;
    }

    html.theme-dark .group-wWM3zP_M- {
        background: #ffffff !important;
    }

    iframe {
        --tv-color-pane-background: #ffffff;

        html {
            background-color: white;
        }
    }

    .wrap-3tiHesTk div {
        background-color: ${({ theme }) => theme.colors.primaryColor} !important;
    }
`;