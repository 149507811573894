import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface LocationState {
    sub_path: string;
}

const useUserTabStyle = () => {
    const location = useLocation<LocationState>();
    const [dummy, setDummy] = useState(0);

    // useEffect(() => {
    //     setDummy(dummy + 1);
    // }, [window.innerWidth]);

    function handleResize() {
        setDummy((prev) => prev + 1);
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const getStyle = () => {
        if(location.pathname === '/trade/history') {
            return {
                background: '#030B14',
                maxWidth: window.innerWidth,
                // maxWidth: 800,
                minWidth: 1055,
                // maxWidth: '100%',
                // minWidth: '100%',
                // minWidth: 1006,
                // maxWidth: 1006,
                // maxHeight: 385,
                rowHeight: 47,
            };
        } else {
            return {
                maxWidth: window.innerWidth - 445,
                // maxWidth: 800,
                minWidth: 1055,
                // maxWidth: '100%',
                // minWidth: '100%',
                // minWidth: 1006,
                // maxWidth: 1006,
                maxHeight: 385,
                rowHeight: 47,
            };
        }

    };

    const getMobileStyle = () => {
        return {
            maxWidth: '100%',
            maxHeight: '100%',
            rowHeight: 40,
        };
    };

    return { style: getStyle(), mobileStyle: getMobileStyle() };
};

export default useUserTabStyle;
