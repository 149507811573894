import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_ORDER_TAB } from '../../../../states/reducers/orderReducer';
import CustomerTable from '../../../common/CustomerTable';
import LogInRequired from '../../../common/LogInRequired';
import useUpdateData from './useUpdateData';
// import { style, mobileStyle } from '../common/tableStyle';
import useScreenSize from '../../../../hooks/useScreenSize';
import useUserTabStyle from '../../../../hooks/useUserTabStyle';
import socketService from '../../../../states/socketAgent/SocketService';
import useUsersData from '../../../../hooks/useUserData';
import { useTypedSelector } from '../../../../states/useTypedSelector';

const Index = () => {
    const { data, originalData, isSuccess, dataColumn } = useUpdateData();

    const isLoggedIn = useSelector((state) => state.userReducer.isLoggedIn);
    const userReducerData = useTypedSelector((state) => state.userReducer.data);
    const { szAccNo } = userReducerData;
    const dispatch = useDispatch();
    const { isMobile } = useScreenSize();
    const { style, mobileStyle } = useUserTabStyle();

    useEffect(() => {
        const input = {
            Header: {
                function: 'D',
                termtype: 'HTS',
                trcode: 't3600',
            },
            Input1: {
                szAccNo: szAccNo,
            },
        };
        socketService.sendToAgent(input);
    }, [data, isSuccess]);

    const handleClickForOpenOrders = useCallback(
        (d, dealDiv) => (e) => {
            //Open position  => stop/limit index 1
            const input = {
                // idnex 2 === Modify/Cancel order tab
                index: isMobile ? 3 :2,
                triggeredBy: 'open-orders',
                data: {
                    // 매매구분 : modify/cancel은 지정사 매수/매도 주문 취소와 청산limit 매수/매도 주문으로 나눠져있기때문.
                    szDealDiv: dealDiv,
                    // 회원처리항목
                    szCustItem: d[0],
                    // 종목코드
                    szCurNo: d[1],
                    // 주문가격
                    szRate: d[3],
                    // 주문수량
                    fLot: d[4],
                },
            };
            dispatch(UPDATE_ORDER_TAB(input));
        },
        [],
    );

    const openOrdersProps = {
        isSuccess,
        data,
        originalData,
        dataColumn,
        handleClickForOpenOrders,
        tableFor: 'open-orders',
    };

    const switchCase = () => {
        switch (isMobile) {
            case true:
                return isLoggedIn ? (
                    <CustomerTable {...openOrdersProps} {...mobileStyle} />
                ) : (
                    // <CustomerTableForMobile {...openOrdersProps} {...mobileStyle} />
                    <LogInRequired width="100%" height="100%" />
                );
            default:
                return isLoggedIn ? <CustomerTable {...openOrdersProps} {...style} /> : <LogInRequired />;
        }
    };

    return switchCase();
};

export default Index;
