import React from 'react';
import styled, { css } from 'styled-components';

import { Wrap } from './SectionWrap';
import LandingSectionTitle from './LandingSectionTitle';
import LandingSectionImageWrap from './LandingSectionImageWrap';
import LandingSectionButtonWrap from './LandingSectionButtonWrap';
import ContainedButton from './ContainedButton';
import SECTION01 from '../../../assets/landing/section01_pc@2x.png';
import useScreenSize from '../../../hooks/useScreenSize';
import LandingSectionContents from './LandingSectionContents';

const Section01 = () => {
    const { isMobile } = useScreenSize();

    const download = async () => {
        const { PWA, PWA_STATUS} : any = window
        const agent = navigator.userAgent.toLowerCase();
        const filter = [
            agent.indexOf("chrome") != -1,
            PWA_STATUS === true
        ]
        const err_index = filter.indexOf(false)
   
            
        if (err_index === -1) {
            PWA.prompt();
            await PWA.userChoice;
            // console.log(outcome)
        }else{
            const err_res = {
                0: '크롬이 아닌경우 설치가 원활하지 않을수 있습니다',
                1: '이미 설치하셨거나, 설치 가능한 환경이 아닙니다'
            }

            alert(err_res[err_index])
        }
    }
    return (
        <Section mobile={isMobile}>
            <div className="inner">
                <LandingSectionImageWrap>
                    <img src={SECTION01} alt="section1" />
                </LandingSectionImageWrap>
                <div>
                    <LandingSectionTitle>Easy futures trading in my hands.</LandingSectionTitle>
                    <LandingSectionContents>Check it out at Imcosun</LandingSectionContents>
                    <LandingSectionButtonWrap>
                        <ContainedButton onClick={download}>App Download</ContainedButton>
                    </LandingSectionButtonWrap>
                </div>
            </div>
        </Section>
    );
};

export default Section01;

const Section = styled(Wrap)`
    background: #ffffff;
`;
