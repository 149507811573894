import useAgentWhenSignedIn from '../../../hooks/useAgentWhenSignedIn';
import { useTypedSelector } from '../../../states/useTypedSelector';
import { TransactionInputType } from '../../../types';
import useCurrentLanguage from '../../../hooks/useCurrentLanguage';
import { translateOrderType, translateSzPoCode } from './common/commonUtil';

const input = ({ date, szAccNo, email, jwt, subPath }): TransactionInputType => {
    switch(subPath){
        case '/detail':
            return {
                Header: {
                    function: 'D',
                    termtype: 'HTS',
                    trcode: 't3607',
                    userid: email,
                    token: jwt,
                },
                Input1: {
                    szAccNo,
                    nFromDate: date.fromDate,
                    nToDate: date.toDate,
                },
            };
        case '/close':
            return {
                Header: {
                    function: 'D',
                    termtype: 'HTS',
                    trcode: 't3644',
                },
                Input1: {
                    szAccNo,
                    nDate: date.fromDate,
                    nToDate: date.toDate,
                    con_key: ''
                },
            };
        default:
            return {
                Header: {
                    function: 'D',
                    termtype: 'HTS',
                    trcode: 't3612',
                    userid: email,
                    token: jwt,
                },
                Input1: {
                    szAccNo,
                    nFromDate: date.fromDate,
                    nToDate: date.toDate,
                },
            };

    }
};

const output2Column = {
    t3612: [
        '회원처리항목',
        '체결번호',
        '종목코드2',
        '주문수량',
        '체결수량',
        '매매구분',
        '주문환율',
        '체결환율',
        '주문형태',
        '주문시각',
        '체결시각',
        '소수최저자리',
    ],
    t3607: [
        '회원처리항목',
        '체결번호',
        '종목코드',
        '매매구분',
        '주문수량',
        '주문환율',
        'Stop주문환율',
        'Limit주문환율',
        '주문유형',
        '주문형태',
        '주문상태',
        '주문시각',
        '관리자ID',
        'IP_Address'
    ],
    t3644: [
        '체결번호',
        '계좌번호',
        '종목번호',
        '청산포지션수량',
        '진입시각',
        '청산체결시각',
        '매수환율',
        '매도환율',
        '손익(지수)',
        '손익',
        '매입수수료',
        '청산수수료',
        '주문형태',
        '매매구분',
        '소수최저자리',
    ]
};

const output2ColumnInEng = {
    t3612: [
        'Order No',
        'Execution No',
        'Symbol',
        'Order Lot',
        'Execution Lot',
        'Order Kinds',
        'Order Price',
        'Execution Price',
        'Order Type',
        'Order Time',
        'Execution Time',
        'Point position'
    ],
    t3607: [
        'Order No',
        'Execution No',
        'Symbol',
        'Side',
        'Lot',
        'Order Price',
        'Stop Price,',
        'Limit Price',
        'Order Kinds',
        'Order Type',
        'Stat',
        'Order Time',
        'Manager ID',
        'IP_Address'
    ],
    t3644: [
        'Execution No',
        'Account No',
        'Symbol',
        'Close Lot',
        'Open Time',
        'Close Time',
        'Open Price',
        'Close Price',
        'P&L Price',
        'P&L',
        'Open Commission',
        'Close Commission',
        'Order Type',
        'Side',
    'Point Position'
]
};

const output2ColumnInEngArch = {
    t3612:[
        'szCustItem',
        'szPrOpen_No',
        'szCurNo',
        'fOrderSu',
        'fNxOpenSu',
        'szPOCode',
        'fOrderPrice',
        'fExecPrice',
        'szOrderType',
        'szOrderDateTime',
        'szExecDateTime',
        'fPipLowest',
    ],
    t3607:[
        'szCustItem',
        'szPrOpen_No',
        'szCurNo',
        'szPOCode',
        'fOrderSu',
        'fOrderPrice',
        'fOrderPrice',
        'fOrderPrice',
        'szOrderStyle',
        'szOrderType',
        'szOrderType_Last',
        'szOrderDateTime',
        'szStaff_ID',
        'szIP_address',
    ],
    t3644:[
        'szPrOpen_No',
        'szAccNo',
        'szCur_No',
        'nLot',
        'szPrOpenDateTime',
        'szClosePrOpenDateTime',
        'fSellRate',
        'fBuyRate',
        'fPipPL_pip',
        'fPipPL',
        'fOM',
        'fCM',
        'szOrderType',
        'szPOCode',
        'nPipLowest',
    ]
};

type ReturnType = {
    data: Array<Array<string | number>>;
    dataColumn: Array<string>;
    output1: {
        con_gb: string 
        con_key: string 
        nCloseCnt: number 
        nPosCnt: number
        szCnt: string
        szMsg: string
        fCommission: number
        fPNL: number
};
};

const useData = ({ date, subPath }): ReturnType => {
    date = {fromDate: String(date.fromDate), toDate: String(date.toDate)}
    const userReducerData = useTypedSelector((state) => state.userReducer.data);
    const { szAccNo, email, jwt } = userReducerData;
    const { currentLanguage } = useCurrentLanguage();

    const inputField = input({ date, szAccNo, email, jwt, subPath });
    const trCode = inputField.Header.trcode;
    const { trResult } = useAgentWhenSignedIn({
        input: inputField,
        dependency: [inputField.Input1.nDate, inputField.Input1.nFromDate, inputField.Input1.nToDate, subPath],
    });
    // console.log('@@@@@@')
    // console.log(inputField)
    // console.log(trResult)

    const parseData = (data) => {
        const newData = [...data];
        switch(trCode){
            case 't3612':
                return newData.map((d) => {
                    const newD = [...d];
                    // 회원처리번호
                    newD[0] = newD[0].slice(15, 21);
                    // 체결번호
                    newD[1] = newD[1].slice(10, 16);
                    // szPOCode 주문 유형 예 : 079 080
                    newD[5] = translateSzPoCode(newD[5]);
                    // UOE --> Limit, UOM --> Market, SCM -> Close
                    newD[8] = translateOrderType(newD[8]);
    
                    //(1) 079 -> Buy, 081 -> Sell, 080 -> Close Sell, 082 -> Close Buy
                    return newD;
                });
            case 't3607':
                return newData.map((d) => {
                    const newD = [...d];
                    // 회원처리번호
                    newD[0] = newD[0].slice(15, 21);
                    // 체결번호
                    newD[1] = newD[1].slice(10, 16);
                    // szPOCode 주문 유형 예 : 079 080
                    newD[3] = translateSzPoCode(newD[3]);
                    // UOE --> Limit, UOM --> Market, SCM -> Close
                    newD[8] = translateOrderType(newD[8]);
                    newD[9] = translateOrderType(newD[9]);
    
                    //(1) 079 -> Buy, 081 -> Sell, 080 -> Close Sell, 082 -> Close Buy
                    return newD;
                });
            case 't3644':
                return newData.map((d) => {
                    const newD = [...d];
    
                    // 회원처리번호
                    newD[0] = newD[0].slice(10, 16);
                    // newD[3] = translateSzPoCode(newD[3]);
                    // 체결번호
                    // newD[1] = newD[1].slice(15, 21);
                    // // szPOCode 주문 유형 예 : 079 080
                    // newD[5] = translateSzPoCode(newD[5]);
                    // // UOE --> Limit, UOM --> Market, SCM -> Close
                    newD[12] = translateOrderType(newD[12]);
                    newD[13] = translateSzPoCode(newD[13]);
    
                    //(1) 079 -> Buy, 081 -> Sell, 080 -> Close Sell, 082 -> Close Buy
                    return newD;
                });
        }

        return newData;
    };

    return {
        data: trResult && trResult.Output2 ? parseData(trResult.Output2) : [],
        dataColumn: output2ColumnInEng[trCode],
        // conKey: trResult?.Output1?.con_key || '',
        output1: trResult?.Output1
    };
};

export default useData;
