import React, { useEffect, useState } from 'react';
import { NavLink, RouteComponentProps, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { Button, ClickAwayListener, Grow, IconButton, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';

import socketService from '../../states/socketAgent/SocketService';
import { useTypedSelector } from '../../states/useTypedSelector';
import { USER_LOG_OUT } from '../../states/reducers/userReducer';
import { DELETE_TR, RESET_TR } from '../../states/reducers/agentReducer';

import useScreenSize from '../../hooks/useScreenSize';
import useUsersData from '../../hooks/useUserData';

import MobileNavigation from './MobileNavigation';
import LogoutButton from '../common/LogoutButton';

import MobileMenu from '../svgs/MobileMenu';
import User from '../svgs/User';
import CountDownTimer from './CountDownTImer';

interface IProps extends RouteComponentProps {
    theme?: 'dark' | 'light';
}
const Navigation = ({ theme }: IProps) => {
    const dispatch = useDispatch();
    const szAccNo = useTypedSelector((state) => state.userReducer.data.szAccNo);
    const loout = useTypedSelector((state) => state.stateReducer.loout);

    const [openMobileNav, setOpenMobileNav] = useState(false);
    const { isMobile } = useScreenSize();
    const location = useLocation();
    const { pathname } = location;


    const [loading, setLoading] = useState(false);
    const [openWallet, setOpenWallet] = useState(false);
    const [openEvent, setOpenEvent] = useState(false);
    const [openTrade, setOpenTrade] = useState(false);
    const [openSupport, setOpenSupport] = useState(false);

    const walletAnchorRef = React.useRef<HTMLButtonElement>(null);
    const eventAnchorRef = React.useRef<HTMLButtonElement>(null);
    const tradeAnchorRef = React.useRef<HTMLButtonElement>(null);
    const supportAnchorRef = React.useRef<HTMLButtonElement>(null);
    const prevOpenWallet = React.useRef(openWallet);
    const prevOpenEvent = React.useRef(openEvent);
    const prevOpenTrade = React.useRef(openSupport);
    const prevOpenSupport = React.useRef(openSupport);
    const { isLoggedIn, email } = useUsersData();


    useEffect(() => {
        return () => {
            dispatch(DELETE_TR({ key: `loout`, data: [] }))
        }
    }, [])

    useEffect(() => {
        if (loout) {
            const { flag, data } = loout.Message;
            switch (flag) {
                case 'E':
                    alert(data);
                    // setLoading(false);
                    break;
                case '0':
                    dispatch(USER_LOG_OUT());
                    // reset시 order book도 사라짐?
                    // dispatch(RESET_TR());
                    setLoading(false);
                    break;
                default:
                    alert(data);
            }
        }
    }, [loout, loading]);

    useEffect(() => {
        if (prevOpenWallet.current && !openEvent) {
            walletAnchorRef.current!.focus();
        }
        prevOpenWallet.current = openEvent;
    }, [openEvent]);

    useEffect(() => {
        if (prevOpenEvent.current && !openEvent) {
            eventAnchorRef.current!.focus();
        }

        prevOpenEvent.current = openEvent;
    }, [openEvent]);

    useEffect(() => {
        if (prevOpenSupport.current && !openSupport) {
            supportAnchorRef.current!.focus();
        }

        prevOpenSupport.current = openSupport;
    }, [openSupport]);

    useEffect(() => {
        if (prevOpenTrade.current && !openTrade) {
            tradeAnchorRef.current!.focus();
        }

        prevOpenSupport.current = openTrade;
    }, [openTrade]);

    const handleOpenWallet = () => {
        setOpenWallet((state) => !state);
    };

    const handleOpenEvent = () => {
        setOpenEvent((state) => !state);
    };

    const handleOpenTrade = () => {
        setOpenTrade((state) => !state);
    };
    const handleOpenSupport = () => {
        setOpenSupport((state) => !state);
    };

    const handleClose = (event: React.MouseEvent<EventTarget>, type: 'event' | 'support' | 'wallet' | 'trade') => {
        switch (type) {
            case 'event':
                if (eventAnchorRef.current && eventAnchorRef.current.contains(event.target as HTMLElement)) {
                    return;
                }
                setOpenEvent(false);
                break;
            case 'support':
                if (supportAnchorRef.current && supportAnchorRef.current.contains(event.target as HTMLElement)) {
                    return;
                }

                setOpenSupport(false);
                break;
            case 'trade':
                if (tradeAnchorRef.current && tradeAnchorRef.current.contains(event.target as HTMLElement)) {
                    return;
                }

                setOpenTrade(false);
                break;
            case 'wallet':
                if (walletAnchorRef.current && walletAnchorRef.current.contains(event.target as HTMLElement)) {
                    return;
                }

                setOpenWallet(false);
                break;
            default:
                break;
        }
    };

    const handleListKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpenEvent(false);
        }
    };

    const handleOpenMobileNav = () => {
        setOpenMobileNav((state) => !state);
    };

    const handleClickConstruction = () => {
        alert('Service is being prepared.');
    };


    const walletHandleClose = (e) => handleClose(e, 'wallet');
    const tradeHandleClose = (e) => handleClose(e, 'trade');

    const wallet_loaction = {
        location_1: {
            pathname: '/wallet',
            state: { sub_path: '/asset', szAccNo: szAccNo },
        },
        location_2: {
            pathname: '/wallet',
            state: { sub_path: '/history', szAccNo: szAccNo },
        },
        location_3: {
            pathname: '/wallet',
            state: { sub_path: '/convert', szAccNo: szAccNo },
        },
        location_4: {
            pathname: '/wallet',
            state: { sub_path: '/transfer', szAccNo: szAccNo },
        },
        location_5: {
            pathname: '/wallet',
            state: { sub_path: '/deposit_withdraw', szAccNo: szAccNo },
        },
    };

    const trade_loaction = {
        location_1: {
            pathname: '/execution',
            state: { sub_path: '/list', szAccNo: szAccNo },
        },
        location_2: {
            pathname: '/execution',
            state: { sub_path: '/detail', szAccNo: szAccNo },
        },
        location_3: {
            pathname: '/execution',
            state: { sub_path: '/close', szAccNo: szAccNo },
        },
    };
    const handleSubmit = () => {
        const liveTrCodes = ['96', '95', '98'];
        liveTrCodes.map(v => {
            socketService.sendToAgent({
                Header: {
                    function: 'U', // 응답시 'F'
                    termtype: 'HTS',
                    trcode: v,
                },
                Input1: {
                    // 응답시 "Input" 없슴
                    Key1: szAccNo, // 계좌번호(key)
                },
            })
        })
        socketService.sendToAgent({
            Header: { function: 'D', termtype: 'HTS', trcode: 'loout' },
            Input1: { 
                szMemberNo: "000",
                szCustId: email,
                cUserLevel : "4",
                szIPAddress : "",
                cFlag : "1"
            },
        })
        setLoading(true);
    };


    return (
        <StyledNavigation mobile={isMobile}>
            {isMobile ? (
                <>
                    <IconButton onClick={handleOpenMobileNav}>
                        <MobileMenu fill={theme === 'light' ? '#404040' : '#FFFFFF'} />
                    </IconButton>
                    {openMobileNav && <MobileNavigation onClose={handleOpenMobileNav} />}
                </>
            ) : (
                <Inner>
                    <NavigationWrap>
                        <Menu to="/trade" theme={theme}>
                            Futures Trade
                        </Menu>
                        <div>
                            <MultiMenu
                                ref={walletAnchorRef}
                                aria-controls={openWallet ? 'menu-list-grow' : undefined}
                                aria-haspopup="true"
                                onClick={handleOpenWallet}
                            >
                                <Menu to={location} theme={theme}>
                                    Wallet ▾
                                </Menu>
                            </MultiMenu>
                            <Popper
                                open={openWallet}
                                anchorEl={walletAnchorRef.current}
                                role={undefined}
                                transition
                                disablePortal
                            >
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{
                                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                                        }}
                                    >
                                        <Paper>
                                            <ClickAwayListener onClickAway={walletHandleClose}>
                                                <SubMenuWrap
                                                    autoFocusItem={openWallet}
                                                    id="menu-list-grow"
                                                    onKeyDown={handleListKeyDown}
                                                >
                                                    <MenuItem onClick={walletHandleClose}>
                                                        <Menu
                                                            to={wallet_loaction.location_1}
                                                            activeStyle={{ fontWeight: 'bold' }}
                                                        >
                                                            My Asset
                                                        </Menu>
                                                    </MenuItem>
                                                    <MenuItem onClick={walletHandleClose}>
                                                        <Menu
                                                            to={wallet_loaction.location_2}
                                                            activeStyle={{ fontWeight: 'bold' }}
                                                        >
                                                            History
                                                        </Menu>
                                                    </MenuItem>
                                                    <MenuItem onClick={walletHandleClose}>
                                                        <Menu
                                                            to={wallet_loaction.location_3}
                                                            activeStyle={{ fontWeight: 'bold' }}
                                                        >
                                                            Convert
                                                        </Menu>
                                                    </MenuItem>
                                                    {/* <MenuItem onClick={walletHandleClose}>
                                                        <Menu to={wallet_loaction.location_4} activeStyle={{ fontWeight: 'bold' }}>
                                                        Transfer
                                                        </Menu>
                                                    </MenuItem> */}
                                                    <MenuItem onClick={walletHandleClose}>
                                                        <Menu
                                                            to={wallet_loaction.location_5}
                                                            activeStyle={{ fontWeight: 'bold' }}
                                                        >
                                                            Deposit
                                                        </Menu>
                                                    </MenuItem>
                                                </SubMenuWrap>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </div>
                        <div>
                            <MultiMenu
                                ref={tradeAnchorRef}
                                aria-controls={openTrade ? 'menu-list-grow' : undefined}
                                aria-haspopup="true"
                                onClick={handleOpenTrade}
                            >
                                <Menu to={location} theme={theme}>
                                    Trade History ▾
                                </Menu>
                            </MultiMenu>
                            <Popper
                                open={openTrade}
                                anchorEl={tradeAnchorRef.current}
                                role={undefined}
                                transition
                                disablePortal
                            >
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{
                                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                                        }}
                                    >
                                        <Paper>
                                            <ClickAwayListener onClickAway={(e) => handleClose(e, 'trade')}>
                                                <SubMenuWrap
                                                    autoFocusItem={openEvent}
                                                    id="menu-list-grow"
                                                    onKeyDown={handleListKeyDown}
                                                >
                                                    <MenuItem onClick={tradeHandleClose}>
                                                        <Menu
                                                            to={trade_loaction.location_1}
                                                            activeStyle={{ fontWeight: 'bold' }}
                                                        >
                                                            Execution List
                                                        </Menu>
                                                    </MenuItem>
                                                    <MenuItem onClick={tradeHandleClose}>
                                                        <Menu
                                                            to={trade_loaction.location_2}
                                                            activeStyle={{ fontWeight: 'bold' }}
                                                        >
                                                            Oder/execution Detail
                                                        </Menu>
                                                    </MenuItem>
                                                    <MenuItem onClick={tradeHandleClose}>
                                                        <Menu
                                                            to={trade_loaction.location_3}
                                                            activeStyle={{ fontWeight: 'bold' }}
                                                        >
                                                            Close Execution List
                                                        </Menu>
                                                    </MenuItem>
                                                </SubMenuWrap>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </div>

                        <div>
                        <Menu to="/metaverse/avatar-trader" theme={theme}>
                            Metaverse Trader
                        </Menu>
                        <Menu to="/guide/deposit" theme={theme}>
                            Support/Exchange
                        </Menu>
                        </div>
                    </NavigationWrap>

                    <RegisterWrap mobile={isMobile}>
                        {isLoggedIn ? (
                            <div className="login-user">
                                <CountDownTimer theme={theme} />
                                <User fill={theme === 'light' ? '#404040' : '#FFFFFF'} />
                                <p style={{ color: theme === 'light' ? '#404040' : '#FFFFFF' }}>{email}</p>
                                {/* <Menu to="/mobile/signin" style={{ margin: 0 }} theme={theme}>
                                    <LogoutButton theme={theme} onClick={handleSubmit} />
                                </Menu> */}
                                {/* <Menu to="/mobile/signin" style={{ margin: 0 }} theme={theme}> */}
                                    <LogoutButton theme={theme} onClick={handleSubmit} />
                                {/* </Menu> */}
                            </div>
                        ) : (
                            <>
                                <Menu to="/mobile/signin" theme={theme}>
                                    Login
                                </Menu>
                                <Menu to="/mobile/signup" style={{ margin: 0 }} theme={theme}>
                                    <ContainedButton mobile={isMobile} variant="contained">
                                        Register
                                    </ContainedButton>
                                </Menu>
                            </>
                        )}
                    </RegisterWrap>
                </Inner>
            )}
        </StyledNavigation>
    );
};

export default withRouter(Navigation);

const StyledNavigation = styled.div<{ mobile: boolean }>`
    width: 100%;
    ${({ mobile }) =>
        mobile &&
        css`
            display: flex;
            justify-content: flex-end;
            align-items: center;
        `}
`;
const Inner = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

export const RegisterWrap = styled.div<{ mobile: boolean }>`
    .login-user {
        display: flex;
        align-items: center;
        > svg {
            margin-right: 5px;
        }
        .count-down-timer {
            display: inline-block;
            margin-right: 15px;
            cursor: pointer;
            .login-timer {
                display: inline-block;
                font-size: 14px;
                line-height: 19px;
                color: #ffffff;
                margin: 0 5px 0 5px;
            }
            .refresh-icon {
                display: inline-block;
                vertical-align: middle;
                width: 13px;
                margin: 5px 0;
                > g {
                    fill: #fff;
                }
            }
        }
    }
    ${({ mobile }) =>
        mobile &&
        css`
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;
            width: 100%;
            height: 100px;
            text-align: center;
        `}
`;

const Menu = styled(NavLink)<{ theme?: 'dark' | 'light' }>`
    width: auto;
    margin-left: 10px !important;
    margin-right: 28px;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    text-decoration: none;
    ${({ theme }) =>
        theme === 'light' &&
        css`
            color: #000000;
        `}
`;

const NavigationWrap = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: 900px;
    width: 100%;
`;

const MultiMenu = styled(Button)`
    height: 100%;
    margin-right: 20px;
    text-transform: none !important;

    span {
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
    }
`;
export const SubMenuWrap = styled(MenuList)`
    width: 200px;
    height: auto;
    background-color: #2f4c68;
    overflow: hidden;
    padding: 10px;
`;
export const ContainedButton = styled(Button)<{ mobile: boolean }>`
    width: 120px;
    height: 32px;
    background: #ffab2e !important;
    border-radius: 4px;
    padding: 6px 10px;
    text-transform: unset !important;
    & > span {
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #ffffff;
    }
    ${({ mobile }) =>
        mobile &&
        css`
            width: 100%;
            height: 48px;
        `}
`;
