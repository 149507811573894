import React, { useEffect, useState } from 'react';
import styled, {css} from 'styled-components';
import { useDispatch } from 'react-redux';


import ScrollBar from '../../../../styled/ScrollBar';
import { useTypedSelector } from '../../../../states/useTypedSelector';
import socketService from '../../../../states/socketAgent/SocketService';
import { DELETE_TR } from '../../../../states/reducers/agentReducer';
import useUsersData from '../../../../hooks/useUserData';
import useScreenSize from '../../../../hooks/useScreenSize';

import Loading from '../../../../components/svgs/Loading';

interface IBaseTableProps {
    data: Array<any>;
    dataColumn: Array<any>;
    maxWidth?: number | string;
    maxHeight?: number | string;
    rowHeight?: number;
    disableClick?: boolean;
    handleClick?: (data: Record<string, any>) => () => void;
}


const BaseTable = ({
    data = [],
    dataColumn = [],
    maxWidth = 580,
    maxHeight = 580,
    rowHeight = 60,
    disableClick = false,
    handleClick = () => () => {
        // alert('function not provided');
    },
}: IBaseTableProps) => {
    const dispatch = useDispatch();
    const { email, jwt, szAccNo } = useUsersData();
    const { isMobile } = useScreenSize();

    const t0306 = useTypedSelector((state) => state.stateReducer.t0306)
    const t3216 = useTypedSelector((state) => state.stateReducer.t3216)
    const [loading, setLoading] = useState(false);

    const reloadAsset = () => {
        socketService.sendToAgent({
            Header: {
                function: 'D',
                termtype: 'HTS',
                trcode: 't3720',
                userid: email,
                token: jwt,
            },
            Input1: {
                szAccNo: szAccNo,
            },
        })
    }

    useEffect(() => {
        if(!t0306){
            socketService.sendToAgent({
                Header: {
                    function: 'D',
                    termtype: 'HTS',
                    trcode: 't0306',
                },
                Input1: {
                    select_flag: '0',
                    comp_code: "000",
                    hts_id: email,
                    name: ''
                },
            });
        }
    }, [])

    const emptyHandleClick = () => () => {
        // click is disabled
    };
    const reqCounterTrade = (d) => {
        if(t0306 && t0306.Output2 && d){
            dispatch(DELETE_TR({ key: `t3216`, data: null }))
            // setLoading(true);
            socketService.sendToAgent({
                Header : {
                    function : "D",termtype : "HTS", trcode : "t3216"},
                    Input1 : {
                        szAccNo : szAccNo, szPasswd : t0306.Output2[0][3], szCurNo : d[0], fOrderSu : d[1], fOrderPrice : d[4],
                        fStopPrice : "", fLimitPrice : "", szOrdType : "UOM", nRange : "0", nAlivingTerm : "",
                        szDealDiv : d[2] === "Buy" ? "081" : "079", fNxOpenRate : "", szSLCustItem : "", szOrgCustItem : "", szNotMemberAccNo : "", szStaffID : "",
                        szStaffPW : "", cIsStaff : "0",cModType : "4", margin_type: "0",  leverage:"10"
                }
            });
        }
    } 

    useEffect(() => {
        if (loading && t3216) {
            const { flag, data } = t3216.Message;
            switch (flag) {
                case 'E':
                    alert(data);
                    setLoading(false);
                    break;
                case '0':
                    // alert("접수가 완료됐습니다");
                    setLoading(false);
                    // reloadAsset()
                    break;
                default:
                    alert(data);
            }
        }
    }, [t3216, loading]);

    const keys = dataColumn;
    const rowStyle = {};

    if (loading) {
        return <Loading/>
    }

    if(!isMobile){
        return (
            <Wrapper>
                <StTable>
                    <thead>
                        <HeaderRow>
                            {keys &&
                                keys.map((key, i) => (
                                    <th key={key}>
                                        <div></div>
                                        {key}
                                    </th>
                                ))}
                        </HeaderRow>
                    </thead>
                    {data.length > 0 ? (
                      <tbody>
                      {data.map((d, i) => {
                          return   <ContentRow
                          key={i}
                          style={rowStyle}
                          onClick={!disableClick ? handleClick(d) : emptyHandleClick()}
                        >
                            {keys.map((key, index) => {
                                return  index !== 10 ? <TableData key={index} num={index} val={d[index]}>{d[index]}</TableData> : <CounterTradeButton onClick={() => reqCounterTrade(d)}><button>Close All</button></CounterTradeButton>
                            })}
                        </ContentRow>
                      })}
                      </tbody>
                    ) : (
                      <tbody>
                      <ContentRow style={rowStyle}>
                          <td className="empty" colSpan={keys.length}>No data was retrieved</td>
                      </ContentRow>
                      </tbody>
                    )}
                </StTable>
            </Wrapper>
        )
    }else{
        const allowed_index = [0, 1, 2, 3,4, 6, 10]

        return (
            <Wrapper>
                <StTable>
                    <thead>
                        <HeaderRow>
                            {keys &&
                                keys.filter((key, i) => allowed_index.includes(i)).map((key, i) => (
                                    <th key={key}>
                                        <div></div>
                                        {key}
                                    </th>
                                ))}
                        </HeaderRow>
                    </thead>
                    {data.length > 0 ? (
                      <tbody>
                      {data.map((d, i) => {
                          return   <ContentRow
                          key={i}
                          style={rowStyle}
                          onClick={!disableClick ? handleClick(d) : emptyHandleClick()}
                        >
                            {/* filter 진행시 index 변경으로 d[index]값 오류남 */}
                            {keys.map((key, index) => {
                                if(allowed_index.includes(index)){
                                    return  index !== 10 ? <TableData key={index} num={index} val={d[index]}>{d[index]}</TableData> : <CounterTradeButton onClick={() => reqCounterTrade(d)}><button>Close All</button></CounterTradeButton>
                                }
                            })}
                        </ContentRow>
                      })}
                      </tbody>
                    ) : (
                      <tbody>
                      <ContentRow style={rowStyle}>xw
                          <td className="empty" colSpan={keys.length}>No data was retrieved</td>
                      </ContentRow>
                      </tbody>
                    )}
                </StTable>
            </Wrapper>
        );
    }
};

export default React.memo(BaseTable);

const Wrapper = styled(ScrollBar)`
    overflow: auto;
    height: 385px;
`;

const HeaderRow = styled.tr``;
const ContentRow = styled.tr`
    height: ${({ theme }) => theme.tabMenu.rowHeight};
    .empty{
        text-align: center;
        padding: 150px 0px;
    }
    /* &:hover {
  background-color: #f3ebeb !important;
} */
`;
const StTable = styled.table`
    border-collapse: collapse;
    border-radius: 15px;
    width: 100%;
    color: ${({ theme }) => theme.colors.normalTextColor};

    ${HeaderRow} {
        white-space: nowrap;

        th {
            border-bottom: 1px solid ${({ theme }) => theme.colors.secondaryColor};
            text-align: center;
            color: ${({ theme }) => theme.colors.normalTextColor};
            background: ${({ theme }) => theme.colors.primaryColor};
            font-size: ${({ theme }) => theme.fontSizes.content};

            padding: 7px 15px;
            position: sticky;
            top: 0px;
        }
    }
`;

const TableData = styled.td<{num: number, val: number}>`

    ${props =>
        props.num === 6 && props.val > 0 &&  
        css`
            color:rgb(65, 218, 120);
    `}
    ${props =>
        props.num === 6 && props.val < 0 &&  
        css`
            color:red;
    `}
    white-space: nowrap;
    text-align: center;
    font-size: ${({ theme }) => theme.fontSizes.content};
    vertical-align: middle;
    border-bottom: 1px solid ${({ theme }) => theme.colors.secondaryColor};
`;

const CounterTradeButton = styled.div`
    width: 100%;
    padding: 0 10px;
    & > button{
        cursor: pointer;
        width: 100%;
        height: 30px; 
        margin-top: 4px;
        border: 0px;
        color: #ffffff;
        background-color: #00a1ffcc;
    }
`
